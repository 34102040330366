import { Link } from "react-router-dom"
import { trackedClicked } from "../HelperFunctions"

export const NotFound = () => {
    return(
        <>
            <h1 id="page-header" data-currentpage="404">404 Page Not Found</h1>
            <Link to="/" onClick={trackedClicked} className="tracked" data-event="gohome">Return to homepage</Link>
        </>
    )
}