import Header from "../components/Header"
import Footer from "../components/Footer"
import * as Proj from "../components/ProjectComponents"
import useDocumentTitle from "../useDocumentTitle";

const currentPageKey = "nearside-design-system-rebrand";

export const NearsideDesignSystemRebrand = () => {

    useDocumentTitle("Nearside Design System Rebrand")

    return(
        <>
            <Header currentPage={currentPageKey}/>

            <Proj.Header projectName="Design System Rebrand" projectKey={currentPageKey} />

            <Proj.Description>
                <p>I joined Nearside when it was still called Hatch, and I was the first design hire under (who would become) our Head of Design. Since she was the sole founding designer for quite some time, the design system was understandably in need of some organization. This was one of my first major projects.</p>
                <p>After some time, Hatch rebranded to Nearside. It would be time to revamp the design system I had created, also creating an implementation plan for our engineers. Collaborating with many teams internal and external, this project would span the duration of my tenure at Nearside.</p>
            </Proj.Description>

            <Proj.Heading headingText="Creating the Initial Design System" />

            <Proj.ImageLeft imageName="nearside-ds-colors-hatch-update.jpg">
                <h4>Color Styles</h4>
                <p>The palette was well defined, but in need of some organization. It also needed more guidance on how to use the colors.</p>
                <p>My first goal was to make the color documentation more clear on how they were to be used, organizing them based on visual design style principles. This first round was solely for the design team, focused on how we used the colors. This worked fine for our small team, but over time explaining to new designers & developers how to use each color was getting pretty tedious. It was clear that I needed to lean harder into the functional colors idea.</p>
                <p>For the second round, I got the developers involved. We took inventory of how certain colors were used, where there was overlap, and identified a lot of colors we never actually used in our products. We recategorized them, but more importantly we renamed the colors to reflect their usage. Now instead of remembering that <span className="code">tintBlue</span> is the background color we use sometimes, you can just use the color called <span className="code">background-tertiary</span>. Designers and developers rejoiced!</p>
            </Proj.ImageLeft>

            <Proj.ImageRight imageName="nearside-ds-type-hatch-update.jpg">
                <h4>Typography Styles</h4>
                <p>Type styles had similar issues to the colors: They were well defined but needed organization and context on how they were used. Around this time we were introducing our first iOS and Android mobile apps, and styles for those needed to be defined as well.</p>
                <p>Working with our developers, we settled on some common names so we could reference them across all platforms. The matrix approach made the most sense here, so we could reference all the styles at a glance.</p>
                <p>Not every style was present on all platforms, and we decided that was fine. It was better to define only what we actually used in production, and we could add styles as needed.</p>
            </Proj.ImageRight>

            <Proj.ImageLeft imageName="nearside-ds-icons-hatch-update.jpg">
                <h4>Icon Library</h4>
                <p>The main issue with icons was the lack of any source of truth. Most of the icons in our library we didn&apos;t use, and any icons we did use were found scattered through the designs themselves. This involved a lot of hunting for some screen somewhere in the design files, and copying the icon you needed.</p>
                <p>First thing I did was to audit the icons in use. I did this partially with designs in Figma, and partially by looking for icons in our code repositories. I made sure to originate them all at the same pixel size, to make sure we could use consistent strokes and padding for creating new icons in the future.</p>
                <p>This involved heavy collaboration with developers, as we would need to deliver icon assets with designs. We came up with a system that used a source icon set, which we could embed in context-specific component sets for use in designs. More details on this later.</p>
                <p>This made it so much easier for designers to find the appropriate icon to use, and for developers to receive the right assets without duplicates.</p>
            </Proj.ImageLeft>
            
            <Proj.Heading headingText="Design System, Assemble!" />

            <Proj.Description>
                <p>At this point the base system was pretty well defined, but we still had a lot of components to create. So we assembled the inaugural cross functional design system team. The team consisted of designers on both the product and marketing sides, as well as developers from the web, iOS, and Android teams.</p>
                <p>We had kickoff meetings with each development team to get a sense of how they used current design elements, and what they might want to see changed in future iterations. We set guidelines around how to update style elements, as well as make changes to components. The goal was to keep what we had in Figma as close as possible to what was implemented.</p>
                <p>We set up a Notion space just for design system things. This included a resources page for design files, definitions, meeting notes, and links to Slack channels and Shortcut boards to track work. Designers could sign up to create components, and we had a page for guidelines on creating components in Figma.</p>
            </Proj.Description>

            <Proj.ImageFull imageName="nearside-ds-team-notion.jpg" />


            {/* REBRAND THINGS */}

            <Proj.Heading headingText="Rebranding" />

            <Proj.Description>
                <p>We used an external agency to do the foundational visual design work on a full company rebranding. Name, website, logo, colors, everything. I was the lead designer on the product side of the rebranding. I worked with our entire design team for feedback as well as members of web, iOS, and Android engineering teams for planning and execution.</p>
            </Proj.Description>

            <Proj.ImageLeft imageName="nearside-ds-rebrand-full-examples.jpg">
                <h4>Rebrand Goal: Phase 1</h4>
                <p>Our first phase of rebranding was intended to apply the new brand styles and assets to our product. No major layout changes were to happen yet, in order to make the most use of our development resources as we neared our deadline. This applied to all platforms: web, iOS, and Android.</p>
            </Proj.ImageLeft>

            <Proj.ImageRight imageName="nearside-ds-colors-rebrand.jpg">
                <h4>Colors</h4>
                <p>The design agency defined a nice palette, but was in serious need of organizing for product use. We used this opportunity to lean even harder on our functional colors idea, but this time the engineering teams were all in from the get go, making it easier to match our Figma and development definitions exactly. We made sure to keep it realistic, only defining the functional colors we were actually going to use.</p>
            </Proj.ImageRight>

            <Proj.ImageLeft imageName="nearside-ds-type-rebrand.jpg">
                <h4>Typography</h4>
                <p>Similar situation with the agency defined text styles, they were a nice start but not defined enough for our use. Since the typography matrix was working well for all teams, we decided to keep with that organization.</p>
                <p>We were also to exactly align our Figma styles with the engineering styles, adding helpful style variations like <span className="code">Emphasized</span> and <span className="code">Link</span> to base styles that would use them. After using it for a while, both designers and engineers had mentioned how incredibly helpful this was when using the styles.</p>
            </Proj.ImageLeft>



            {/* ADD PHASE 0 */}

            <Proj.Heading headingText="Adjusting the Plan" />

            <Proj.ImageLeft imageName="nearside-ds-phase-examples.jpg">
                <h4>What comes before Phase 1?</h4>
                <p>No plan ever goes off without a hitch, and this was no exception. Due to some engineering resource and timing issues, there were some design changes that had to happen before the official launch date. Since other things were timed with that date, including our new marketing site launch and a customer communication campaign, we had to reassess our phased plan.</p>
                <p>The constraint here was that a middle phase was necessary, where some work would have to live, but it would launch early. So we had to do work in this phase that wouldn&apos;t surprise users with entirely new branding.</p>
            </Proj.ImageLeft>
            
            <Proj.ImageRight imageName="nearside-ds-mapping-phase-0.jpg">
                <h4>Phase 0</h4>
                <p>This new &ldquo;Phase 0&rdquo;, as we started to call it, included some lightweight style items that wouldn&apos;t alter the brand perception too much. This included using the new fonts, rounded button styles, updating corner radii on container elements, and moving from our old drop shadow card style to the updated outlined style. All things that could launch early, but not be jarring to the user.</p>
            </Proj.ImageRight>

            <Proj.ImageLeft imageName="nearside-ds-mapping-phase-1.jpg">
                <h4>Phase 1</h4>
                <p>The remainder of the rebranding work would be items that had to be timed with the official go live date, things that would alter the brand perception pretty heavily. These included updating the buttons with new colors, updating the entire color palette to use the new brand system, and updating the graphics assets with the new style.</p>
                <p>In order to not have to adjust layouts, new graphics were mapped to existing graphics. This let the engineers use the &ldquo;find and replace&rdquo; method to replace them easily.</p>
            </Proj.ImageLeft>
            
            <Proj.ImageRight imageName="nearside-ds-rebrand-complete.jpg">
                <h4>Conclusion</h4>
                <p>The journey from that initial design system creation through the rebrand was long, but very rewarding. With each iteration of the design system, we were able to work more closely with our engineering teams to make sure what we designed matched what was implemented. I am personally very proud of where our design system ended up, a long way from the &ldquo;team of one&rdquo; project it started as.</p>                
            </Proj.ImageRight>



            <Proj.BottomNavigation  previousLink="/nearside-term-loans/" nextLink="/august-apple-watch/" />

            <Footer />
        </>
    )
}