import Footer from '../components/Footer.js'
import Header from '../components/Header.js'
import AboutMeHero from '../components/AboutMeHero.js'
import PortfolioGroup from '../components/PortfolioGroup.js'
import useDocumentTitle from '../useDocumentTitle.js'

export const HomePage = () => {

    useDocumentTitle("Product Designer")

    return(
        <>
            <Header currentPage="home" />
            <AboutMeHero />
            <PortfolioGroup />
            <Footer />
        </>
    )
}