import Header from "../components/Header"
import Footer from "../components/Footer"
import * as Proj from "../components/ProjectComponents"
import useDocumentTitle from "../useDocumentTitle";

const currentPageKey = "august-apple-watch";

export const AugustAppleWatch = () => {

    useDocumentTitle("August Apple Watch and Widget")

    return(
        <>
        <Header currentPage={currentPageKey}/>

        <Proj.Header projectName="Apple Watch &amp; iOS Widget" projectKey={currentPageKey} />


        <Proj.Description>
            <p>Some technical requirements from Apple required that we rewrite our Apple Watch app, which was a great way to prioritize some problems we wanted to address.</p>
            <h4>Problems to address:</h4>
            <ul>
                <li>Interactions were too slow</li>
                <li>Multiple locks were difficult to navigate</li>
                <li>No text status was displayed on screen, only graphic</li>
                <li>If lock status couldn&apos;t be obtained, user couldn&apos;t operate their lock</li>
            </ul>

            <h4>Results:</h4>
            <ul>
                <li>Much faster interactions when standing in front of your door</li>
                <li>User can operate the lock without waiting for lock status, only retrieved when needed</li>
                <li>Multiple locks were simple to scroll through, while still optimizing for the common single lock user</li>
                <li>Text labels were added for clarity</li>
            </ul>
        </Proj.Description>
    
        <Proj.ImageFull imageName="august-apple-watch-screenshots-in-watch.jpg" />

        <Proj.Heading headingText="Previous Version" />

        <Proj.ImageLeft imageName="august-apple-watch-previous-app-flow.jpg">
            <h4>Previous flow limitations</h4>
            <ul>
                <li>User had to wait for current lock status before they could unlock their door</li>
                <li>If current lock status couldn&apos;t be retrieved, user could not operate their door</li>
                <li>There was no text describing the lock or door status, which was sometimes vague or confusing</li>
            </ul>
        </Proj.ImageLeft>

        <Proj.Heading headingText="Redesigned Version" />

        <Proj.ImageRight imageName="august-apple-watch-redesigned-app-flow.jpg">
            <h4>New flow improvements</h4>
            <ul>
                <li>No need to wait for lock status before unlocking the door</li>
                <li>Removes an error possibility without the initial lock status request</li>
                <li>Added text makes lock and door status much clearer</li>
            </ul>
        </Proj.ImageRight>

        <Proj.Heading headingText="iOS Widget" />

        <Proj.ImageLeft imageName="august-apple-watch-widget-screens.jpg">
            <h4>Shared design language</h4>
            <p>We gave the iOS widget the same treatment as the Apple Watch. Since widgets can be accessed with the phone locked, we added a security check to make sure the phone is unlocked before showing any lock controls.</p>            
        </Proj.ImageLeft>



        <Proj.BottomNavigation  previousLink="/nearside-design-system-rebrand/" nextLink="/august-auto-unlock/" />

        <Footer />
        </>
    )
}