import Header from "../components/Header"
import Footer from "../components/Footer"
import * as Proj from "../components/ProjectComponents"
import useDocumentTitle from "../useDocumentTitle";

const currentPageKey = "wendid";

export const Wendid = () => {

    useDocumentTitle("Wendid")

    return(
        <>
        <Header currentPage={currentPageKey}/>

        <Proj.Header projectName="Wendid" projectKey={currentPageKey} />


        <Proj.Description>
            <h4>Problem:</h4>
            <p>Dates are difficult to remember, making recalling life events problematic. This is made worse by the fact that as the years go on, our memories lose&nbsp;clarity.</p>
            <h4>Solution:</h4>
            <p>Store dates of life events in one place, and display that time-based information in a meaningful&nbsp;way.</p>

            <h4>External Links</h4>
            <a className="link-dark" target="_blank" rel="noreferrer" href="http://www.jeffheppert.com/wendid/app/">Mobile App Prototype</a>
            <a className="link-dark" target="_blank" rel="noreferrer" href="http://wendidprocess.tumblr.com/">Full Process Journal</a>
        </Proj.Description>

        <Proj.Heading headingText="Why?" />
        
        <Proj.Description>
            <p>The concept of a Quantified Self has permeated many aspects of our daily lives (i.e. finance, sleep, activity tracking). One area that hasn’t had the benefit of being &ldquo;everyday quantifiable&rdquo; is how we spend the overall time in our&nbsp;lives.</p>
        </Proj.Description>

        <Proj.Heading headingText="What is Wendid?" />
        
        <Proj.Description>
            <p>Wendid will provide users with a simple reference to the dates and durations of important events in their lives. Using time as its main data point, the information will be visually interpreted in a way that is easy to understand.</p>
            <p>This will allow people to have a visual representation of how they spend their time, and make it easy to share their timelines with&nbsp;friends.</p>
        </Proj.Description>


        {/* STUFF */}


        <Proj.Heading headingText="User Interface" />
        
        <Proj.VideoLeft videoURL="wendid-tabs-animation.mp4">
            <h4>Navigation Tabs</h4>
            <p>Early on, I thought to go with a hamburger menu in the top left corner for navigation. After working through the information architecture, I realized that these four primary screens form the foundation of every user flow. Tabbed navigation was the most practical, and followed iOS human interface&nbsp;guidelines.</p>
        </Proj.VideoLeft>


        <Proj.ImageFull imageName="wendid-tab-screens.png" />
        


        {/* TIMELINE VIEWS */}


        <Proj.Heading headingText="Data Visualization" />
        
        <Proj.VideoLeft videoURL="wendid-timeline-views-animation.mp4">
            <h4>Viewing Your Timeline</h4>
            <p>Organizing your life is no small task, and people process information in different ways. List view lays out details of each event for finding the specific one you are looking for, while Bars view is great for visualizing how events relate to each other over time. Boxes and Bubbles views are great for seeing which kinds of events you do spend the most time&nbsp;doing.</p>
        </Proj.VideoLeft>

        <Proj.ImageFull imageName="wendid-timeline-views-mockup.png" />



        {/* EVENT IMPORTING */}

        <Proj.Heading headingText="User Input" />

        <Proj.ImageLeft imageName="wendid-adding-stuff.png">
            <h4>Forms Suck</h4>
            <p>An issue I wanted to avoid was requiring users to fill out form after form to input their life events. A solution to alleviate this was to connect with various social media networks to automatically import events. Facebook knows about your personal life, LinkedIn knows about your professional life, and Foursquare knows about the day-to-day places your&nbsp;visit.</p>
            <p>Users can also allow the app to find current users in their contact lists. No remembering usernames, email addresses or phone numbers. Simply find a friend in the list and add them to your friends&nbsp;list.</p>
        </Proj.ImageLeft>

        <Proj.VideoLeft videoURL="wendid-view-single-event-scroll.mp4">
                <h4>Automatic Media Association</h4>
                <p>Because events are imported from social media, Wendid is able to gather its associated photos, videos, and geographic information in one place&nbsp;automatically.</p>
        </Proj.VideoLeft>

        <Proj.ProcessContainer>
        <div className="process-dual-image-caption mobile-100">
            <a href="../img/wendid-sketch-ia.jpg">
                <img src="../img/wendid-sketch-ia.jpg" alt="" />
            </a>
            <div>
                <p>Early sketches to work out information&nbsp;architecture.</p>
            </div>
            </div>
            <div className="process-dual-image-caption mobile-100">
                <a href="../img/wendid-sketch-ui.jpg">
                    <img src="../img/wendid-sketch-ui.jpg" alt="" />
                </a>
                <div>
                    <p>Early sketches for general UI elements and layout. Working on displaying the list of events and tabbed&nbsp;navigation.</p>
                </div>
            </div>
            <div className="clear"></div>
            <h4>Wireframes in Sketch</h4>
            <div className="project-image-full">
                <img src="img/wendid-sketch-wireframes.png" alt="" className="image-full" />
            </div>
            <h4>High Fidelity Screens in Sketch</h4>
            <div className="project-image-full">
                <img src="../img/wendid-sketch-screens.png" alt="" className="image-full" />
            </div>
        </Proj.ProcessContainer>




        {/* USER FLOWS */}



        <Proj.Heading headingText="UX Process" />

        <Proj.ImageDual headingText="User Flow 1" imageLeftName="wendid-persona-primary.png" imageRightName="wendid-ia-add-friends.png" />

        <Proj.IFrameLeft videoURL="https://player.vimeo.com/video/166455790?title=0&amp;byline=0&amp;portrait=0">
            <h4>Add Friends From Contacts</h4>
            <p>The Dashboard tab is the default view when opening the app. Tapping the Friends tab shows the empty state of the friends list, which instructs the user to tap the Add button to find friends. A notification asks the user for approval to access their contacts before the native iOS notification, because if they dismiss the native notification it is much harder to enable it&nbsp;later.</p>
            <p>The Wendid app can determine who is already a user of the service, and lets you invite all your friends currently using Wendid at one time. This option is not present for non-members, as many people have contacts they may not know very&nbsp;well.</p>
        </Proj.IFrameLeft>



        <hr />

        <Proj.ImageDual headingText="User Flow 2" imageLeftName="wendid-persona-secondary.png" imageRightName="wendid-ia-add-event.png" />

        <Proj.IFrameLeft videoURL="https://player.vimeo.com/video/166455854?title=0&amp;byline=0&amp;portrait=0">
            <h4>Add an Event From Facebook</h4>
            <p>When a new user signs up, they will likely want to add a life event to start making use of the service. After a simple sign up form, an empty dashboard prompts the user to add&nbsp;events.</p>
            <p>Connecting through social media services utilizes the information that already exists, so the process is as hassle-free as possible. Connecting with the Facebook authorization presents the user with the longest events first, with the rest of their events just a tap&nbsp;away.</p>
            <p>Events imported with social media have the added benefit of importing any associated photos and videos, along with date and location&nbsp;information.</p>
        </Proj.IFrameLeft>


        <hr />

        <Proj.ImageDual headingText="User Flow 3" imageLeftName="wendid-persona-primary.png" imageRightName="wendid-ia-view-events.png" />

        <Proj.IFrameLeft videoURL="https://player.vimeo.com/video/167362944?title=0&amp;byline=0&amp;portrait=0">
            <h4>View Events 11 Years Ago</h4>
            <p>The app opens to the Dashboard. Navigating to the timeline allows you to select Bars from the View menu. In the Bars view, you can drag your finger to select events in the highlighted time period. Once highlighted, you can view more detail in list view for the selected events.</p>
        </Proj.IFrameLeft>




        {/* BRANDING */}



        <Proj.Heading headingText="Branding" />

        <Proj.ImageFull imageName="wendid-branding.png" />

        <Proj.ProcessContainer>
            <div className="process-dual-image-caption mobile-100">
                <a href="../img/wendid-name-brainstorming.png">
                    <img src="../img/wendid-name-brainstorming.png" alt="" />
                </a>
                <div>
                    <p>Brainstorming for names. I was working towards the idea of time, the past, and hopefully one word. In the end I narrowed it down to Wendid and Hap&rsquo;n. I chose Wendid because it seemed to directly answer the problem&nbsp;question.</p>
                </div>
            </div>
            <div className="process-dual-image-caption mobile-100">
                <a href="../img/wendid-mood-board.png">
                    <img src="../img/wendid-mood-board.png" alt="" />
                </a>
                <div>
                    <p>Mood board for colors and feel. I really wanted a friendly, almost playful&nbsp;feel.</p>
                </div>
            </div>
        </Proj.ProcessContainer>







        <Footer />
        </>
    )
}