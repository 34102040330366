import Header from "../components/Header"
import Footer from "../components/Footer"
import * as Proj from "../components/ProjectComponents"
import { trackedClicked } from "../HelperFunctions";
import useDocumentTitle from "../useDocumentTitle";

const currentPageKey = "august-auto-unlock";

export const AugustAutoUnlock = () => {

    useDocumentTitle("August Auto-Unlock")

    return(
        <>
        <Header currentPage={currentPageKey}/>

        <Proj.Header projectName="Auto-Unlock" projectKey={currentPageKey} />

        <Proj.Description>
            <p>Auto-Unlock is one of our flagship features, and how the majority of our users unlock their doors when they return home. It is a technically complex feature, and works a bit differently than some users expect it to.</p>
            <p>The misunderstanding of the feature, combined with some implementation quirks, contributed to a large amount of customer support tickets and negative app store ratings. We launched a project to completely overhaul the feature.</p>

            <p>We would focus both on the technical implementation to make it perform better, as well as the interface with the user to make it smarter and easier to understand. The overhaul was performed on iOS first, as it is the vast majority of our user base, followed by Android a year later.</p>

            <h4>Symptoms:</h4>
            <ul>
                <li>An large number of negative app store ratings were because of Auto-Unlock issues.</li>
                <li>The source of a large percentage of customer support tickets.</li>
            </ul>

            <h4>Problems to address:</h4>
            <ul>
                <li>The underlying algorithm was leading to a significant number of failures.</li>
                <li>Customers did not have a proper understanding of how the feature worked, and assumed it was malfunctioning in cases where it was actually working as designed.</li>
            </ul>

            <h4>Impact of the redesign:</h4>
            <ul>
                <li>Decrease in customer support tickets related to Auto-Unlock.</li>
                <li>Increase in app store ratings, most significant on Android.</li>
                <li>Sustained an extremely high success rate.</li>
                <li>Increase in usage of the Auto-Unlock feature.</li>
            </ul>

            <h4>The team:</h4>
            <ul>
                <li>UX Designer (me)</li>
                <li>UX Director</li>
                <li>Product Manager</li>
                <li>Customer Support Manager</li>
                <li>iOS developer</li>
                <li>Android developer</li>
            </ul>
        </Proj.Description>

        <Proj.ImageFull imageName="august-auto-unlock-setup.jpg" />

        <Proj.Heading headingText="Previous Version" />

        <Proj.ImageLeft imageName="august-auto-unlock-original-setup.jpg">
            <h4>Previous design problems</h4>
        <p>During setup of the feature, the swipe-navigated explainer screens did not do a great job of explaining how it worked. The graphics were too abstract, and users just skimmed by them. This caused many users to misunderstand how it works, and expect their doors to lock when it shouldn't. Or worse, unlock when they did not expect them to.</p>
        </Proj.ImageLeft>

        <Proj.Heading headingText="Visual Design" />

        <Proj.ImageFull imageName="august-auto-unlock-visual-design.jpg" />

        <Proj.IFrameLeft videoURL="https://player.vimeo.com/video/475319054?byline=0&title=0&loop=1">
            <h4>Designed for Animation</h4>
            <p>I had planned for this setup to be animated, with each screen's graphic animating to the next. The development time did not allow for the animation to be implemented unfortunately. Here is an early proof of concept of the animation idea.</p>
        </Proj.IFrameLeft>

        <Proj.Heading headingText="User feedback" />
        
        <Proj.ImageRight imageName="august-auto-unlock-report-problem.jpg">
            <h4>Reporting problems</h4>
            <p>We implemented an easy way to give feedback right from the notification, which helped the team quickly address issues & edge cases.</p>
            <h4>Alpha testers</h4>
            <p>Early alpha users tested the feature as it was being developed, allowing for timely feedback. This feedback mechanism allowed our developer to incorporate real user feedback right away to improve the algorithm.</p>
        </Proj.ImageRight>

        <Proj.ImageLeft imageName="august-auto-unlock-kb-article-computer.jpg">
            <h4>Knowledge base articles</h4>
            <p>As part of the customer support experience, I wrote the knowledge base articles on how the feature works. They are on our main knowledge base website, and linked to from within the Auto-Unlock settings for users needing more information.</p>
            <p>Live article: <a className="link-dark" onClick={trackedClicked} data-event="kb-article" target="_blank" rel="noreferrer" href="https://support.august.com/auto-unlock-for-ios-BkKgyDUkC_M">How to use Auto-Unlock for iOS</a></p>
        </Proj.ImageLeft>




        <Proj.BottomNavigation  previousLink="/august-apple-watch/" nextLink="/nearside-term-loans/" />

        <Footer />
        </>
    )
}