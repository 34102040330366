import "../genericons.css"
import { trackedClicked } from "../HelperFunctions";

function Footer() {
    return (
        <footer id="footer" className="footer mobile-100">
            <h2>Let's connect:</h2>
            <div className="email-section">
                <a href="mailto:jeff@jeffheppert.com" onClick={trackedClicked} target="_blank" rel="noreferrer" className="tracked" data-event="Email">jeff@jeffheppert.com</a>
            </div>
            <a href="https://www.linkedin.com/in/jeffheppert" onClick={trackedClicked} className="social-link genericon genericon-linkedin-alt tracked" target="_blank" rel="noreferrer" data-event="LinkedIn"><div className="tooltip">LinkedIn</div></a>
            <a href="http://codepen.io/jheppert/pens/public/" onClick={trackedClicked} className="social-link genericon genericon-codepen tracked" target="_blank" rel="noreferrer" data-event="CodePen"><div className="tooltip">CodePen</div></a>
            <a href={process.env.PUBLIC_URL + "/downloads/Resume-Jeff_Heppert.pdf"} onClick={trackedClicked} className="social-link genericon genericon-document tracked" target="_blank" rel="noreferrer" data-event="Resume"><div className="tooltip">Resume</div></a>
            <p className="copyright">&copy; Jeff Heppert {new Date().getFullYear().toString()}</p>
        </footer>
    );
}

export default Footer;