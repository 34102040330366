import { scrollToElement } from "../HelperFunctions.js"
import Logo from '../img/logo.svg'
import LogoCondensed from '../img/logo-condensed.svg'
import { Link } from "react-router-dom"
import { trackedClicked } from "../HelperFunctions.js"

function ScrollAndTrack(e) {
    trackedClicked(e);
    scrollToElement(e);
}

function Header({ currentPage }) {

    var linkToHomepage
    var linkToPortfolio

    if (currentPage === "home") {
        linkToPortfolio =   <a href="#" id="portfolioLink" onClick={ScrollAndTrack} data-scroll="portfolio" className="tracked" data-event="portfolio-link">Portfolio</a>;
        linkToHomepage =    <a onClick={ScrollAndTrack} data-scroll="about-me" className="logo-link" data-event="logo-link">
                                <img src={Logo} alt="Jeff Heppert" className="logo" width="1339" height="253" />
                                <img src={LogoCondensed} alt="Jeff Heppert" className="logo-condensed" />
                            </a>
    } else {
        linkToPortfolio =   <Link to="/" id="portfolioLink" onClick={trackedClicked} className="tracked" data-event="portfolio-link">Portfolio</Link>;
        linkToHomepage =    <Link to="/" className="logo-link" onClick={trackedClicked} data-event="logo-link">
                                <img src={Logo} alt="Jeff Heppert" className="logo" width="1339" height="253" />
                                <img src={LogoCondensed} alt="Jeff Heppert" className="logo-condensed" />
                            </Link>
    }

    return (
        <div className="header" id="page-header" data-currentpage={currentPage}>
            {linkToHomepage}
            <ul className="nav">
                <li>{linkToPortfolio}</li>
                <li><a href="#" id="contactLink" onClick={ScrollAndTrack} data-scroll="footer" className="tracked" data-event="contact">Contact</a></li>
            </ul>
        </div>
    );
}

export default Header;