import { Link } from "react-router-dom"
import { trackedClicked } from "../HelperFunctions";

function PortfolioGroupItem({ projectKey, imageName, titleText }) {
    return (
        <section className={"project-header " + projectKey + " homepage"}>
            <Link to={projectKey + "/"} onClick={trackedClicked} data-event={projectKey}><img src={require('../img/' + imageName)} alt="" /><h2>{titleText}</h2></Link>
        </section>
    );
}

export default PortfolioGroupItem;