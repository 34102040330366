import { Link } from "react-router-dom"
import { trackedClicked, toggleProcess } from "../HelperFunctions"

export function Header({projectName, projectKey}) {
    return(
        <section className={"project-header " + projectKey}>
            <h1 className="white">{projectName}</h1>
            {/* <img src="img/wendid-logo-white.png" alt="" /> */}
        </section>
    )
}


export function Description({children}) {
    return(
        <section className="project-description">
            <div className="project-description-text">
                {children}
            </div>
        </section>
    )
}


export function Heading({headingText}) {
    return(
        <section className="project-heading">
            <h2>{headingText}</h2>
        </section>
    )
}


export function ImageFull({ imageName }) {
    return(
        <section className="project-image-full">
            <img src={process.env.PUBLIC_URL + "/img/" + imageName} alt="" className="image-margin" />
        </section>
    )
}


export function ImageLeft({imageName, children}) {
    return(
        <section className="project-image-left">
            <a href={process.env.PUBLIC_URL + "/img/" + imageName} className="image-left-big mobile-100" onClick={trackedClicked} data-event={imageName}>
                <img src={process.env.PUBLIC_URL + "/img/" + imageName} alt="" />
            </a>
            <div className="text-right-small mobile-100">
                {children}
            </div>
        </section>
    )
}



export function ImageRight({imageName, children}) {
    return(
        <section className="project-image-right">
            <a href={process.env.PUBLIC_URL + "/img/" + imageName} className="image-right-big mobile-100" onClick={trackedClicked} data-event={imageName}>
                <img src={process.env.PUBLIC_URL + "/img/" + imageName} alt="" />
            </a>
            <div className="text-left-small mobile-100">
                {children}
            </div>
        </section>
    )
}


export function ImageDual({ imageLeftName, imageRightName }) {
    return(
        <section className="project-image-dual">
            <img src={process.env.PUBLIC_URL + "/img/" + imageLeftName} alt="" className="image-half mobile-100" />
            <img src={process.env.PUBLIC_URL + "/img/" + imageRightName} alt="" className="image-half mobile-100" />
        </section>
    )
}




export function IFrameLeft({videoURL, children}) {

    return(
        <section className="project-image-left">
            <iframe title={videoURL} className="image-left-big mobile-100" src={videoURL} style={{border: "none"}} allow="autoplay; fullscreen"></iframe>
            <div className="text-right-small mobile-100">
                {children}
            </div>
        </section>
    )
}


export function VideoLeft({videoURL, children}) {

    return(
        <section className="project-image-left">
            <video loop autoPlay src={process.env.PUBLIC_URL + "/img/" + videoURL} className="video-left-small mobile-100"></video>
            <div className="text-right-small mobile-100">
                {children}
            </div>
        </section>
    )
}





export function ProcessContainer({ children }) {
    return(
        <section className="process contrasting-background">
            <div className="process-toggle tracked" onClick={toggleProcess} data-event="process-toggle-event-importing">Show Process</div>
            <div className="process-content">
                {children}
                <div className="clear"></div>
                <div className="process-toggle-inside tracked" onClick={toggleProcess} data-event="process-toggle-event-importing-inside">Hide Process</div>
            </div>
        </section>
    )
}





export function BottomNavigation({ nextLink, previousLink }) {
    return(
        <section className="bottom-nav">
            <Link to={previousLink} onClick={trackedClicked} data-event="previous-project" className="prev-project">&larr; Previous Work</Link>
            <Link to={nextLink} onClick={trackedClicked} data-event="next-project" className="next-project">Next Work &rarr;</Link>
            <div className="clear"></div>
        </section>
    )
}