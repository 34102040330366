import React from 'react';
import ArrowIndicator from '../img/arrow-indicator.png'
import { ReactTyped } from 'react-typed';
import { scrollToElement } from "../HelperFunctions.js"

function Footer() {

    return (
        <section id="about-me" className="about-me">
            <div className="about-headline">
                <h1>I&rsquo;m Jeff.</h1>
                <h1>I&rsquo;m an interactive product designer.</h1>
                <h1>I&rsquo;m all about&nbsp;
                    <ReactTyped 
                        strings={[
                            "the user experience", 
                            "making stuff", 
                            "solving problems."
                        ]} 
                        typeSpeed={100} />
                    </h1>
            </div>
            <a href="#" className="arrow-indicator" onClick={scrollToElement} data-scroll="portfolio">
                <img height={11} width={32} src={ArrowIndicator} alt="" />
            </a>
        </section>
    );
}

export default Footer;