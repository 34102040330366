export function scrollToElement(e) {
    e.preventDefault();
    const headerHeight = -80;
    const destinationText = e.currentTarget.getAttribute('data-scroll');
    const targetElement = document.getElementById(destinationText);
    const yPosition = targetElement.getBoundingClientRect().top + window.scrollY + headerHeight;
    window.scrollTo({ top: yPosition, behavior: "smooth"});
    // targetElement?.scrollIntoView({behavior: 'smooth'});
}


export function trackedClicked(e) {
    const clickedEvent = e.currentTarget.getAttribute("data-event");
    const currentPage = document.getElementById("page-header").getAttribute("data-currentpage");
    
    // For debug purposes:
    // console.log(clickedEvent + " on " + currentPage);

    var eventName = 'R_' + clickedEvent + '_' + currentPage;

    window.gtag('event', eventName, {
        'pageTitle': currentPage
    });
}


export function toggleProcess(e) {
    var processContent
    
    if(e.currentTarget.classList.contains("process-toggle")) {
        processContent = e.currentTarget.parentNode.childNodes[1];
    } else if(e.currentTarget.classList.contains("process-toggle-inside")) {
        processContent = e.currentTarget.parentNode;
    } else {
        console.log("Error toggling process section visibility");
        return;
    }

    if(processContent.classList.contains("open")) {
        processContent.classList.remove("open");
    } else {
        processContent.classList.add("open");
    }

}