// import { useRef, useEffect } from 'react'
import { useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
    // const defaultTitle = useRef(document.title);
  
    useEffect(() => {
      if(title !== "") {
        document.title = "Jeff Heppert | " + title;
      } else {
        document.title = "Jeff Heppert"
      }
    }, [title]);
  
    // useEffect(() => () => {
    //   if (!prevailOnUnmount) {
    //     document.title = defaultTitle.current;
    //   }
    // }, [])
  }
  
  export default useDocumentTitle