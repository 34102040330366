import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage.js";
import { NotFound } from "./pages/NotFound.js";
import { NearsideTermLoans } from "./pages/NearsideTermLoans.js";
import { NearsideDesignSystemRebrand } from "./pages/NearsideDesignSystemRebrand.js";
import { AugustAppleWatch } from "./pages/AugustAppleWatch.js";
import { AugustAutoUnlock } from "./pages/AugustAutoUnlock.js";
import { Wendid } from "./pages/Wendid.js";
import ScrollToTop from "./components/ScrollToTop.js";

export const RouteConfigurations = () => {
    return(
        <BrowserRouter>
        <ScrollToTop />
            <Routes>
                <Route exact path="/" element={<HomePage />} errorElement={<NotFound />} />

                <Route exact path="/nearside-term-loans/" element={<NearsideTermLoans />} />
                <Route exact path="/nearside-design-system-rebrand/" element={<NearsideDesignSystemRebrand />} />
                <Route exact path="/august-apple-watch/" element={<AugustAppleWatch />} />
                <Route exact path="/august-auto-unlock/" element={<AugustAutoUnlock />} />

                <Route exact path="/wendid/" element={<Wendid />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}