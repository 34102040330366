import PortfolioGroupItem from './PortfolioGroupItem';

function PortfolioGroup() {
    return (
        <div id="portfolio">
            <PortfolioGroupItem 
                projectKey={'nearside-term-loans'}
                imageName={'nearside-logo-white.png'}
                titleText={'Term Loans Product'}
            />

            <PortfolioGroupItem 
                projectKey={'nearside-design-system-rebrand'}
                imageName={'nearside-logo-white.png'}
                titleText={'Design System: Creation and Rebranding'}
            />

            <PortfolioGroupItem 
                projectKey={'august-apple-watch'}
                imageName={'august-logo-white.png'}
                titleText={'Apple Watch & iOS Widget'}
            />

            <PortfolioGroupItem 
                projectKey={'august-auto-unlock'}
                imageName={'august-logo-white.png'}
                titleText={'Auto-Unlock'}
            />
        </div> 
    );
}

export default PortfolioGroup;