import './App.css';
import { RouteConfigurations } from './RouteConfigurations.js'



function App() {

  return (
    <div className="App">
      <RouteConfigurations />
    </div>
  );
}

export default App;
