import Header from "../components/Header"
import Footer from "../components/Footer"
import * as Proj from "../components/ProjectComponents"
import useDocumentTitle from "../useDocumentTitle";

const currentPageKey = "nearside-term-loans";

export const NearsideTermLoans = () => {

    useDocumentTitle("Nearside Term Loans")

    return(
        <>
            <Header currentPage={currentPageKey}/>

            <Proj.Header projectName="Term Loans" projectKey={currentPageKey} />

            <Proj.Description>
                <p>We wanted to provide a lending option for users, in addition to our flagship business checking product.</p>
                <h4>Why?</h4>
                <p>Lending is one of Nearside&apos;s key pillars for supporting small businesses. We already have a revolving credit card product, which helps users in the short term. We wanted to offer users the chance to level up their business with a loan they could pay back over a longer term.</p>
                <p>Through user interviews, we learned that small business owners typically have to take out high interest loans or borrow from friends & family to make larger improvements for their business. Things like buying supplies, remodeling a brick & mortar, or start a marketing campaign. We wanted to give our users the chance to do those things.</p>
                <h4>The Team</h4>
                <ul>
                    <li >Product Manager</li>
                    <li>Product Designer (Me)</li>
                    <li>Web engineering team</li>
                    <li>Members of the Fraud, Risk, Compliance, and Legal teams</li>
                </ul>
            </Proj.Description>

            <Proj.Heading headingText="The Users" />

            <Proj.Description>
                <h4>Who is this for?</h4>
                <p>Most of our users were very small businesses. I&apos;m talking one person in most cases, oftentimes still having a day job while their small business was getting off the ground. Most were not very savvy financially, and might have less than desirable credit.</p>
                <p>Our primary users were these folks that wanted to take the next step: To finally quit their day job, or to reach the next level in their small business journey. Our goal was to help them do just that.</p>
            </Proj.Description>

            <Proj.Heading headingText="Full User Journey" />

            <Proj.ImageFull imageName="nearside-tl-flowchart.png" />



            {/* Loan Application */}

            <Proj.Heading headingText="Applying for the Loan" />

            <Proj.ImageLeft imageName="nearside-tl-flowchart-application.png">
                <h4>Application Flow</h4>
                <p>For the first phase of this project, we invited existing checking account customers directly via email. Later we expanded to a larger audience with in-app banners displayed in their checking account dashboard.</p>
                <p>Applying for the loan required asking users for additional information, which took many rounds of negotiating between Legal, Fraud, and Compliance departments. We made sure to leverage the information we already had about the user to minimize form filling where possible.</p>
            </Proj.ImageLeft>

            <Proj.ImageLeft imageName="nearside-tl-wireframe-application.jpg">
                <h4>Application Wireframes</h4>
                <p>The purpose of these wireframes were to facilitate stakeholder discussions and make sure we have the correct flow before we flesh out individual pages in higher fidelity. Specific content was not a major part of our initial discussions, so I had a little fun with the copy.</p>
            </Proj.ImageLeft>

            <Proj.Description>
                <h4>Released Application Designs</h4>
                <p>Since the designs spanned multiple pages, a progress bar was added to give users a sense of how much was left. The few confirmation pages let the user edit an information that needed updating, including a new final review page to make sure they entered all the correct information before submitting.</p>
            </Proj.Description>

            <Proj.ImageFull imageName="nearside-tl-finals-application.jpg" />



            {/* Loan Acceptance */}

            <Proj.Heading headingText="Accepting the Loan" />

            <Proj.Description>
                <p>Once a user's application is approved, they need to perform a few additional steps to actually accept the loan & get the funds.</p>
            </Proj.Description>

            <Proj.ImageRight imageName="nearside-tl-flowchart-acceptance.png">
                <h4>Acceptance Flow</h4>
                <p>Since we notify users about their status in multiple ways, there are multiple entry points once they’re approved. A relatively short linear flow, most of which is signing or confirming information, they exit to their new Term Loan dashboard.</p>
            </Proj.ImageRight>

            <Proj.ImageRight imageName="nearside-tl-wireframe-acceptance.jpg">
                <h4>Acceptance Wireframes</h4>
                <p>This flow started as being driven primary by Legal and Compliance, essentially needing the user to sign off on a few agreements. As it evolved beyond wireframes, we tried to make sure the focus was more on educating the user about the terms of their loan.</p>
            </Proj.ImageRight>
            


            <Proj.BottomNavigation  previousLink="/august-auto-unlock/" nextLink="/nearside-design-system-rebrand/" />

            <Footer />
        </>
    )
}